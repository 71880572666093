import { TelegramIcon, VKIcon } from '@/lib/svg'
import { FC } from '@/model/commonModel'

const FooterSocialIcons: FC<unknown> = () => {
  return (
    <div className='d-flex footer__social_icons'>
      <a href='https://t.me/metaversia_ru_chat' title='Telegram'>
        <TelegramIcon />
      </a>
      <a href='https://vk.com' title='VK'>
        <VKIcon />
      </a>
    </div>
  )
}

export default FooterSocialIcons
