import { useEffect, useState } from 'react'

import styles from '@/css/home.module.css'

const CookieBanner = () => {
  const [isAccepted, setIsAccepted] = useState(false)
  const [loading, setLoading] = useState(true)

  const acceptCookies = () => {
    setIsAccepted(true)
    localStorage.setItem('cookieAccepted', 'true')
  }

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted')
    if (cookieAccepted === 'true') {
      setIsAccepted(true)
    }

    setLoading(false)
  }, [])

  if (loading) {
    return null
  }

  if (isAccepted) {
    return null
  }

  return (
    <div className={styles['cookie-banner']}>
      <div className={styles['cookie-content']}>
        <img src='../img/svg/cookie.svg' alt='Cookie Icon' />
        <p>
          Этот веб-сайт использует файлы cookie. <br />
          Продолжая использовать данный сайт, Вы даете согласие на работу с этими файлами.{' '}
          <a className={styles['cookie-href']} href='/privacy'>
            Узнайте больше
          </a>
        </p>
      </div>
      <button onClick={acceptCookies}>Принять</button>
    </div>
  )
}

export default CookieBanner
